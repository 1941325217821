<template>
	<div class="checkout client-content-padding-full">
		<div v-if="!loading && stripeLoaded" class="row">
			<div class="col-lg-6 col-12">
				<img v-if="checkoutBanner.length" class="checkout-banner" :src="checkoutBanner" loading="lazy" />
				<div class="checkout-main-info" v-html="mainInfo"></div>
			</div>
			<div class="col-lg-6 col-12">
				<div class="checkout-price-card">
					<div class="checkout-price-card-value">{{ price }}€ / {{labels.monthly}}</div>
					<div class="checkout-price-card-text">
						{{ mbundleName }}
					</div>
				</div>

				<div class="checkout-agreement-card">
					<div class="checkout-agreement-card-inner">
						<div class="checkout-agreement-card-inner-checkbox">
							<FormulateInput type="checkbox" name="agreement-check" v-model="agreement" />
						</div>
						<div class="checkout-agreement-card-inner-text">
							<div class="checkout-agreement-card-inner-title">
								<span v-html="labels.i_agree_tos"></span>
							</div>
							<div class="checkout-agreement-card-inner-body" v-html="labels.tos_text"></div>
						</div>
					</div>
				</div>

				<div class="payment-simple">
					<StripeElements
						:stripe-key="stripe.publicKey"
						:instance-options="{}"
						:elements-options="{}"
						#default="{ elements }"
						ref="elms"
					>
						<StripeElement
							type="card"
							:elements="elements"
							:options="cardOptions"
							ref="card"
						/>
					</StripeElements>
					<button class="btn btn-primary" type="button">
						{{ labels.pay }}
					</button>
				</div>
			</div>
		</div>
		<SvgLoader v-else :loading="true" bg-color="transparent" />
	</div>
</template>

<script>
import { StripeElements, StripeElement } from 'vue-stripe-elements-plus'
import { mapState, mapActions, mapGetters } from "vuex";
import '@stripe/stripe-js';

export default {
	name: "Checkout",
	components: {
		StripeElements,
    	StripeElement,
		SvgLoader: () => import('@/components/Preloaders/SvgLoader')
	},
	data() {
		return {
			checkoutBanner: '',
			mainInfo: '',
			agreement: false,
			cardOptions: {
				value: {
					postalCode: ''
				}
			},
			loading: true,
			price: {},
			mbundleName: '',
			stripeLoaded: false
		};
	},
	mounted() {
		const interval = setInterval(() => {
			if (typeof window.Stripe == 'function') {
				this.stripeLoaded =  true;
				this.setupCheckoutPage();
				clearInterval(interval);
			}
		}, 100);
	},
	computed: {
		...mapState('helper', ['basicDataLoaded', 'stripe']),
		...mapGetters('helper', ['labels'])
	},
	methods: {
		...mapActions('notification', ['addNotification']),
		...mapActions('account', ['getMonetizationBundle']),
		setupCheckoutPage() {
			// console.log('Stripe key:', this.stripe.publicKey);
			// const stripe = window.Stripe(this.stripe.publicKey);
			// console.log('Stripe:', stripe);
			// const appearance = {
			// 	theme: 'flat',
			// 	variables: { colorPrimaryText: '#262626' }
			// };
			//const options = { /* options */ };
			//const elements = stripe.elements({ clientSecret: this.stripe.publicKey, appearance });
			//console.log('Elements:', elements);
			// const paymentElement = elements.create('payment', options);
			// paymentElement.mount('#payment-element');

			this.loadMBundle();
		},
		loadMBundle() {
			this.loading = true;

			this.getMonetizationBundle(this.$route.params.id).then( res => {
				if (res.success) {
					this.checkoutBanner = res.data?.checkout?.image?.media || ''
					this.mainInfo = res.data?.checkout?.text || ''
					this.price = res.data.pricing.price
					this.mbundleName = res.data.title
				}

				if (res.error) {
					this.addNotification( { variant: 'danger', msg: res.error, labels: this.labels })
				}

				this.loading = false;
			})
			.catch( err => console.log( err ) );
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.checkout {
	.checkout-banner {
		max-width: 100%;
		height: auto;
		object-fit: contain;
		object-position: center;
		margin-bottom: 1rem;
	}

	&-main-info{
		margin-bottom: 25px;
	}
	&-price-card {
		&-value {
			text-align: center;
			font-size: 23px;
			font-weight: 600;
			color: $focus_color;
		}

		&-text {
			font-weight: 500;
		}
	}

	&-agreement-card {
		&-inner {
			display: flex;
			align-items: center;
			gap: 15px;

			&-text {
				display: flex;
				flex-direction: column;
				gap: 10px;
				font-weight: 500;

			}


		}
	}

	&-price-card,
	&-agreement-card {
		background-color: $white;
		border-radius: 5px;
		border: 1px solid $gray_btn;
		padding: 15px 15px;
		margin: 10px 0;
	}
}

::v-deep{
	.formulate-input-element-decorator{
		margin-bottom: 0;
	}
}

.payment-simple {
	background-color: #fff;
    border-radius: 5px;
    border: 1px solid #bdbdbd;
    padding: 15px 15px;
    margin: 10px 0;
	display: flex;
	flex-direction: column;
	align-items: center;

	> div {
		width: 100%;
		border-bottom: 1px solid #bdbdbd;
		padding-bottom: 15px;
	}

	> button {
		margin-top: 15px;
		min-width: 150px;
	}
}
</style>